import React from "react"
import Layout from "../../../components/sermonLayoutSurprise"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout sermonSrc="https://www.youtube.com/embed/4wXtLB-UrgQ">
    <SEO title="The Fiercest Verse in all the Bible - Touching Holiness" />
  </Layout>
)

export default SermonPost
